import Vegimg from "../../assets/foodicons/veg.png";
// import Veganimg from "./icons/vegan.png";
// import OrganicImg from "./icons/organic.png";
// import glutenimg from "./icons/glutenfree.png";
// import spicyimg from "./icons/spicy.png";


const MenuDetails = ({ item }) => {
  console.log("item", item)
  return (

    <article
      className=" mt-4 bg-[#a41d21] shadow-lg rounded-2xl hover:shadow-sm"
    >

      {item?.original_image && <img
        src={item?.original_image?.local_url}
        // loading="lazy"
        alt={item.name}
        className="w-full h-32 rounded-t-2xl object-cover"
      />}

      <div className="pt-3 ml-4 mr-2 mb-3">
        <h3 className="text-xl text-red-50 font-bold">{item.name}</h3>
        {item.description && (
          <p className="text-red-300 text-sm mt-1">{item.description}</p>
        )}
      </div>
      <div className="flex items-center m-2">
        <div className="ml-3 mr-5">
          <span className="block text-3xl text-red-50 font-bold">${item.base_price}</span>

          {/* {
            item?.is_available &&
            <div className="flex-none w-10 h-10 rounded-full">
              <img
                src={Vegimg}
                className="w-full h-full"
                alt="Veg"
              />
            </div>
          } */}


        </div>
      </div>

    </article>
  );
};

export default MenuDetails;
