import { createBrowserRouter } from "react-router-dom";
import Main from "./Main";
import Lunchmenu from "./anarpersiangrill/lunchmenu/lunchmenu";
import Menu from "./Menu/Menu";

const router = createBrowserRouter([
  {
    //TODO: the anar-persian-grill here will be dynamic based on the restaurant name
    path: "/",
    element: <Main />,
    children: [
      {
        path: "/anar-persian-grill/main-menu",
        element: <Menu />,
      },
      {
        path: "/anar-persian-grill/lunch-menu",
        element: <Lunchmenu />,
      },
    ],
  },
]);
export default router;
