import Vegimg from "../../../assets/foodicons/veg.png";
import Veganimg from "../../../assets/foodicons/vegan.png";
import OrganicImg from "../../../assets/foodicons/organic.png";
import glutenimg from "../../../assets/foodicons/glutenfree.png";
import spicyimg from "../../../assets/foodicons/spicy.png";

const Lunchmenu = () => {
  const posts = [
    //add items in this sec
    {
      title: "Loughmeh",
      desc: "One skewer of juicy seasoned premium minced beef and lamb serve with rice and salad",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      href: "javascript:void(0)",
    },
    {
      title: "Joojeh",
      desc: "Boneless Chicken breast serve with rice and salad",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      href: "javascript:void(0)",
    },
    {
      title: "Ghormeh sabzi",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      href: "javascript:void(0)",
    },
    {
      title: "Ghaymeh Bademjoon",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      href: "javascript:void(0)",
    },
    {
      title: "Chicken Curry",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      spicy: true,
      href: "javascript:void(0)",
    },
    {
      title: "Mirza Ghasmi ba polo Vegetarian",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      veg: true,
      vegan: true,
      href: "javascript:void(0)",
    },
    {
      title: "Kashak o bademjoon Vegan",
      priceofitem: "$17.00",
      preptime: "24 min",
      organic: true,
      glutenfree: true,
      veg: true,
      vegan: true,
      href: "javascript:void(0)",
    },
    {
      title: "Ghorme Sabzi Vegan",
      desc: "One skewer of juicy seasoned premium minced beef and lamb serve with rice and salad",
      priceofitem: "$17.00",
      preptime: "24 min",
      veg: true,
      vegan: true,
      href: "javascript:void(0)",
    },
  ];

  return (
    <div className="bg-[#511b1d]">
      <section className="pt-5 pb-5 mx-auto px-4 max-w-screen-xl md:px-8">
        <div className="text-left">
          <h1 className="text-3xl text-red-50 font-semibold">Lunch Menu</h1>
        </div>
        <div className="mt-2 grid gap-2 sm:grid-cols-2 lg:grid-cols-3">
          {posts.map((item, key) => (
            <article
              className=" mt-4 bg-[#a41d21] shadow-lg rounded-2xl hover:shadow-sm"
              key={key}
            >
              <a href={item.href}>
                {item.img && (
                  <img
                    src={item.img}
                    loading="lazy"
                    alt={item.title}
                    className="w-full h-32 rounded-t-2xl object-cover"
                  />
                )}
                <div className="pt-3 ml-4 mr-2 mb-3">
                  <h3 className="text-xl text-red-50 font-bold">
                    {item.title}
                  </h3>
                  {item.desc && (
                    <p className="text-red-300 text-sm mt-1">{item.desc}</p>
                  )}
                </div>
                <div className="flex items-center m-2">
                  <div className="ml-3 mr-5">
                    <span className="block text-3xl text-red-50 font-bold">
                      {item.priceofitem}
                    </span>
                    <span className="block text-red-300 text-sm">
                      {item.preptime}
                    </span>
                  </div>
                  <div className="flex flex-wrap gap-1">
                    {item.veg && (
                      <div className="flex-none w-10 h-10 rounded-full">
                        <img src={Vegimg} className="w-full h-full" alt="Veg" />
                      </div>
                    )}
                    {item.vegan && (
                      <div className="flex-none w-10 h-10 rounded-full">
                        <img
                          src={Veganimg}
                          className="w-full h-full"
                          alt="Vegan"
                        />
                      </div>
                    )}
                    {item.organic && (
                      <div className="flex-none w-10 h-10 rounded-full">
                        <img
                          src={OrganicImg}
                          className="w-full h-full"
                          alt="Organic"
                        />
                      </div>
                    )}
                    {item.glutenfree && (
                      <div className="flex-none w-10 h-10 rounded-full">
                        <img
                          src={glutenimg}
                          className="w-full h-full"
                          alt="GlutenFree"
                        />
                      </div>
                    )}
                    {item.spicy && (
                      <div className="flex-none w-10 h-10 rounded-full">
                        <img
                          src={spicyimg}
                          className="w-full h-full"
                          alt="Spicy"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </article>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Lunchmenu;
