import axios from "axios";

const getMenu = async () => {
    try {
        const response = await axios.get(
            "https://api.chatchefs.com/api/food/v1/menu/",
            {
                params: { locations: "2" },
            }
        );
        return response.data;
    } catch (error) {
        // if (error.message === "Invalid token specified") {
        //     window.location.reload();
        // }

        // return error;
        console.log(error)
    }
};

export {
    getMenu
}