import { useState } from "react";
import { getMenu } from "../../Api/apiQuery";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCallback } from "react";
import MenuDetails from "./MenuDetails";
import Skeleton from "react-loading-skeleton";

const Menu = () => {
  const [menu, setMenu] = useState([]);
  const [categories, setCategories] = useState([]);
  const [itemsName, setItemsName] = useState("Popular items");
  const [filterCategories, setFilterCategories] = useState([]);

  const getMenuQuery = useQuery({
    queryKey: ["getMenu"],
    queryFn: () => getMenu(),
    retry: 1,
    refetchOnWindowFocus: false,

    onSuccess: (data) => {
      setMenu(data?.results[0]?.menuitem_set)
      setCategories(data?.results[0]?.category_set)
    }
  });
  // console.log("itemsName", itemsName);
  // console.log("filterCategories", filterCategories);

  const filterByName = useCallback(() => {
    setFilterCategories(categories.filter((category) => category.name === itemsName))
  }, [categories, itemsName])

  useEffect(() => {
    filterByName()
  }, [filterByName])


  return (
    <div className="bg-[#511b1d]">
      <div className="px-10 md:px-8">
        <ul role="tablist" className="flex max-w-screen-xl mx-auto px-2.5 items-center gap-x-3 overflow-x-auto text-sm bg-[#a41d21] rounded-lg">
          {categories.map((item) => item.name).map((item) => (
            <li key={item} className="py-2">
              <button
                role="tab"
                aria-selected={itemsName === item}
                className={`py-2.5 px-4 rounded-lg duration-150 whitespace-nowrap hover:text-red-50 hover:bg-[#511b1d] active:bg-red-500 font-medium ${itemsName === item
                  ? "bg-[#511b1d] text-red-50 shadow-sm"
                  : "text-red-50"
                  }`}
                onClick={() => setItemsName(item)}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
        {getMenuQuery?.isLoading ? <Skeleton /> : (<div className="px-4 md:px-8">
          {filterCategories.map((category) => (
            <section key={category.id} className="pt-5 pb-5 mx-auto px-4 max-w-screen-xl md:px-8">
              <div className="text-left">
                <h1 className="text-3xl text-red-50 font-semibold">{category.name}</h1>
              </div>
              <div className="mt-2 grid gap-2 sm:grid-cols-2 lg:grid-cols-3">
                {menu
                  .filter((item) => category?.menuitem_set?.includes(item?.id))
                  .map((item) => (
                    <MenuDetails item={item} key={item.id} />
                  ))}
              </div>
            </section>
          ))}
        </div>)}
      </div>
    </div>
  );
};

export default Menu;